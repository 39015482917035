import React from 'react';
import { Inner, Section, SectionTitle } from 'components/index';
import { EcosphereInterface } from 'types/ecosphere';
import Link from 'components/Link';
import tw from 'twin.macro';

function ClusterLogo({ ecosphere }: { ecosphere: EcosphereInterface }) {
  const { clusterLogo, clusterName, clusterWhiteLogo, clusterWebsite } = ecosphere;
  const logo = clusterWhiteLogo || clusterLogo;

  return (
    <Link href={clusterWebsite} target="_blank">
      <div css={tw`mt-4 pr-8 w-full text-center`}>
        <img
          src={logo.url}
          width={logo.width}
          height={logo.height}
          alt={clusterName}
          css={tw`w-auto h-auto max-w-full max-h-[100px] mx-auto`}
        />
      </div>
    </Link>
  );
}

function EcosphereClusterList({
  heading,
  ecospheres,
}: {
  heading: string;
  ecospheres: EcosphereInterface[];
}) {
  if (!ecospheres?.length) {
    return null;
  }

  return (
    <Section show={false}>
      <Inner>
        <SectionTitle tw="mb-10">{heading}</SectionTitle>
        <ul tw="justify-center grid gap-6 md:gap-16 grid-cols-2 md:grid-cols-4">
          {ecospheres.map((ecosphere) => (
            <li key={ecosphere.ecoid} tw="flex items-center justify-center">
              <ClusterLogo ecosphere={ecosphere} />
            </li>
          ))}
        </ul>
      </Inner>
    </Section>
  );
}

export default EcosphereClusterList;
