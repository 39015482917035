import { animate } from 'framer-motion';
import { Easing } from 'framer-motion/types/types';
import { useRef, useEffect } from 'react';

const EASING: Easing = [0.85, 0, 0.15, 1];

function AnimatedNumber({
  from,
  to,
  duration,
  format,
}: {
  from: number;
  to: number;
  duration?: number;
  format?: (n: number) => string;
}) {
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const controls = animate(from, to, {
      duration,
      ease: EASING,
      onUpdate(value) {
        if (ref.current) {
          const formattedValue = format ? format(parseInt(value.toFixed(0), 10)) : value.toFixed(0);
          ref.current.textContent = formattedValue;
        }
      },
    });
    return () => controls.stop();
  }, [from, to, duration, format]);

  return <span ref={ref} />;
}

AnimatedNumber.defaultProps = {
  duration: 1,
  format: undefined,
};

export default AnimatedNumber;
