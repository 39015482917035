import React from 'react';
import tw, { css } from 'twin.macro';
import { Inner, Section, SectionTitle } from 'components/index';
import TiltButton from 'components/TiltButton';
import { TwoPlanesLearnMore } from 'components/Decorations';

const sectionTitleStyles = css`
  margin-top: -60px;
  ${tw`mb-32 w-3/5 md:w-1/2 lg:w-2/5`}
  @media only screen and (max-width: 768px) {
    ${tw`mt-44 w-4/5 mb-10`}
  }
  @media only screen and (max-width: 640px) {
    ${tw`mt-32 mb-10 w-3/5`}
  }
`;

const planeStyles = css`
  pointer-events: none;
  left: 49.5%;
  transform: translateX(-51%);
  ${tw`absolute`}
  @media only screen and (min-width: 1441px) {
    left: 48%;
    transform: translateX(-52%);
  }
  @media only screen and (max-width: 768px) {
    left: auto;
    transform: none;
  }
`;

function Column({ heading, bodyText, cta }) {
  return (
    <li>
      <h3 css={tw`text-[1.25rem] font-bold leading-tight mb-4`}>{heading}</h3>
      <p tw="mt-2 mb-6">{bodyText}</p>
      <TiltButton to={cta.href} label="Read more" accent="#ee105a" external />
    </li>
  );
}

function ThreeColumnCTA({
  heading,
  headingColumn1,
  headingColumn2,
  headingColumn3,
  bodyTextColumn1,
  bodyTextColumn2,
  bodyTextColumn3,
  callToActionColumn1,
  callToActionColumn2,
  callToActionColumn3,
}) {
  return (
    <Section tw="pt-0">
      <Inner>
        <TwoPlanesLearnMore css={planeStyles} />
        <SectionTitle css={sectionTitleStyles}>{heading}</SectionTitle>
        <ul tw="grid gap-12 md:(grid-cols-3 gap-16)">
          <Column heading={headingColumn1} bodyText={bodyTextColumn1} cta={callToActionColumn1} />
          <Column heading={headingColumn2} bodyText={bodyTextColumn2} cta={callToActionColumn2} />
          <Column heading={headingColumn3} bodyText={bodyTextColumn3} cta={callToActionColumn3} />
        </ul>
      </Inner>
    </Section>
  );
}

export default ThreeColumnCTA;
