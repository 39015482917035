import { useEffect, useRef } from 'react';
import tw from 'twin.macro';
import GlueFrame from 'glueframe';
import { Section, Inner } from 'components';

function LargeVideo({ title, videoEmbedUrl, ...rest }) {
  const ref = useRef();

  useEffect(() => {
    const videoTitle = title;
    if (typeof window !== 'undefined') {
      const Iframe = new GlueFrame(ref.current, 'Player');
      Iframe.bind('player:video:playing', () => {
        window.dataLayer = window.dataLayer || [];

        const firstPlay = !window.dataLayer.some(({ event }) => event === 'video_play');
        const sameVideo = window.dataLayer.some((item) => item.video_title === videoTitle);

        if (firstPlay || !sameVideo) {
          window.dataLayer.push({
            event: 'video_play',
            video_title: videoTitle,
          });
        }

        /* if (window.gtag) {
          window.gtag('event', 'video_play', {
            event_category: 'video_play',
            event_label: videoTitle,
          });
        } */
      });
    }
  }, [title]);

  return (
    <Section {...rest}>
      <Inner css={[]}>
        <div css={[tw`relative h-0 w-full pb-[56.25%]`]}>
          <iframe
            ref={ref}
            title={title}
            src={videoEmbedUrl}
            css={[tw`w-full h-full absolute top-0 left-0`]}
            frameBorder="0"
            border="0"
            scrolling="no"
            mozallowfullscreen="1"
            webkitallowfullscreen="1"
            allowFullScreen="1"
            allow="autoplay; fullscreen"
          />
        </div>
      </Inner>
    </Section>
  );
}

export default LargeVideo;
